/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAGPHyUqJ8u_dWRBuG4_b_XjC7wrHuxegE",
  "appId": "1:245973392737:web:dca3e205973f5e37e4b920",
  "authDomain": "schooldog-i-greene-ga.firebaseapp.com",
  "measurementId": "G-YR5421VXC1",
  "messagingSenderId": "245973392737",
  "project": "schooldog-i-greene-ga",
  "projectId": "schooldog-i-greene-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-greene-ga.appspot.com"
}
